import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  PREFIX_LINK,
  LINK_COMPLETED,
  LINKS_HEAD_TRADER,
  INVITE_LINK_HEAD_TRADER,
  PREFIX_INVITE,
  INVITE_LINK_QUANTITATIVE_TRADER,
  LINKS_QUANTITATIVE_TRADER,
} from "./config/env";
import { DataOfHeadTrader } from "./contents/HeadTrader";
import { DataOfQuantitativeTrader } from "./contents/QuantitativeTrader";

import "./index.css";
import App from "./App";

import Main from "./Main";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step0 from "./Step0";

import Complete from "./Complete";

import reportWebVitals from "./reportWebVitals";
import AppProvider from "./Provider/AppProvider";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },

  // Head of Crypto Trading
  {
    path: PREFIX_INVITE + INVITE_LINK_HEAD_TRADER,
    element: <App data={DataOfHeadTrader} links={LINKS_HEAD_TRADER} />,
  },
  {
    path: PREFIX_LINK + LINKS_HEAD_TRADER.step0,
    element: (
      <Step0 links={LINKS_HEAD_TRADER} data={DataOfHeadTrader.question1} />
    ),
  },
  {
    path: PREFIX_LINK + LINKS_HEAD_TRADER.step1,
    element: (
      <Step1 links={LINKS_HEAD_TRADER} data={DataOfHeadTrader.question2} />
    ),
  },
  {
    path: PREFIX_LINK + LINKS_HEAD_TRADER.step2,
    element: (
      <Step2 links={LINKS_HEAD_TRADER} data={DataOfHeadTrader.question3} />
    ),
  },

  // Quantitative Trader
  {
    path: PREFIX_INVITE + INVITE_LINK_QUANTITATIVE_TRADER,
    element: (
      <App data={DataOfQuantitativeTrader} links={LINKS_QUANTITATIVE_TRADER} />
    ),
  },
  {
    path: PREFIX_LINK + LINKS_QUANTITATIVE_TRADER.step0,
    element: (
      <Step0
        links={LINKS_QUANTITATIVE_TRADER}
        data={DataOfQuantitativeTrader.question1}
      />
    ),
  },
  {
    path: PREFIX_LINK + LINKS_QUANTITATIVE_TRADER.step1,
    element: (
      <Step1
        links={LINKS_QUANTITATIVE_TRADER}
        data={DataOfQuantitativeTrader.question2}
      />
    ),
  },
  {
    path: PREFIX_LINK + LINKS_QUANTITATIVE_TRADER.step2,
    element: (
      <Step2
        links={LINKS_QUANTITATIVE_TRADER}
        data={DataOfQuantitativeTrader.question3}
      />
    ),
  },

  // Blockchain Developer
  // {
  //   path: "/invite/Aweo5",
  //   element: <AppBlockchainAwesome />,
  // },

  {
    path: LINK_COMPLETED,
    element: <Complete />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </React.StrictMode>
);

reportWebVitals();
