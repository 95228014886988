import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-100 py-4 text-center text-gray-600">
      <p>&copy; 2024 Willo Technologies Limited | Registered number SC601085</p>
      <p className="mt-2">
        <a href="https://support.willo.video/?_gl=1*u12ods*_gcl_au*NDA2MjUxNjY1LjE3Mjk4MjkyMzE." className="text-blue-400">Support</a> | 
        <a href="https://support.willo.video/category/17-privacy-and-data?_gl=1*u12ods*_gcl_au*NDA2MjUxNjY1LjE3Mjk4MjkyMzE." className="text-blue-400 ml-2">Privacy Policy</a>
      </p>
    </footer>
  );
}

export default Footer;
