import React from "react";
import { Link } from "react-router-dom";
import "./StepBar.css";
import { PREFIX_LINK } from "../config/env";

const StepBar = ({ className, index, links }) => {
  return (
    <div
      className={`step-bar-container flex items-center justify-between ${className}`}
    >
      <Link
        to={`${PREFIX_LINK}${links.step0}`}
        className={`circle ${index === 1 ? "active-step" : "passed-step"}`}
      >
        1
      </Link>
      <div className="line grow" style={{ width: "500px" }}></div>
      <Link
        to={`${PREFIX_LINK}${links.step1}`}
        className={`circle ${
          index === 2 ? "active-step" : index === 3 ? "passed-step" : ""
        }`}
      >
        2
      </Link>
      <div className="line grow" style={{ width: "500px" }}></div>
      <Link
        to={`${PREFIX_LINK}${links.step2}`}
        className={`circle ${index === 3 ? "active-step" : ""}`}
      >
        3
      </Link>
    </div>
  );
};

export default StepBar;
