import React from 'react';

const Header = () => {
  return (
    <header className="bg-purple-100 py-6 flex items-center px-6 flex-wrap">
      <div className="w-1/3 ">
        <img src="/img/logo.png" alt="Company Logo" className="w-12 ml-8 " />
      </div>
      <div className="text-center  w-1/3 ">
        <h1 className="text-lg sm:text-3xl font-bold text-purple-800 mx-4">Recording a great interview</h1>
      </div>
    </header>
  );
}

export default Header;
