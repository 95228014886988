import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { GiConfirmed } from "react-icons/gi";
import Header from './components/Header';
import Footer from './components/Footer';
import StepBar from './components/StepBar';
import RichTextEditor from './components/RichTextEditor';
import Loading from './components/Loading';
import { useAppContext } from './Provider/AppProvider';
import './progressbar.css';

function Complete() {
  const { state, setData } = useAppContext();
  const [count, setCount] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const targetTime = Math.floor(Math.random() * 3) + 3;

  useEffect(() => {
    if (isRunning && count < targetTime) {
      const interval = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setIsRunning(false);
    }
  }, [count, isRunning, targetTime]);

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      {
        isRunning? <Loading/>
        :
        <main className="py-12 grow text-center flex flex-col items-center">
          <GiConfirmed className="text-purple-600 my-8" size={100}/>
          <div className="font-sans text-lg  text-gray-700 font-bold">
            <p>All responses have been submitted successfully!</p>
          </div>
          <p className="font-sans text-2xl  text-gray-700 font-bold my-8"> You're all done </p>
          <div className="font-sans text-lg  text-gray-700">
            <p> Thank you for taking the time to submit a video today. </p>
            <p> Enjoy the rest of your day. 👋</p>
          </div>
          <div className="flex w-full md:w-1/3 mx-auto space-x-8">
            <a href="https://forms.gle/BquRagsxjqWupb6RA" target="_blink" className="mt-12 text-center border border-purple-600 text-purple-600 font-bold text-purple-600 py-2 px-6 rounded-lg text-lg w-2/3" to="/video-questions/create/a888da6b828048c3bb67261efd5e09c2">Rate your experience</a>
            <a href={state.data.companyURL} target="_blink" className="mt-12 text-center bg-purple-600 text-white py-2 px-6 rounded-lg text-lg w-2/3" to="/video-questions/create/a888da6b828048c3bb67261efd5e09c2">Visit website</a>
          </div>
        </main>
      }
      
      <Footer />
    </div>
  );
}

export default Complete;
