// AppContext.js
import React, { createContext, useContext, useReducer } from 'react';

// Initial state
const initialState = {
  data: {
    answer1: "",
    answer2: "",
    companyURL: "https://willotalent.com/"
  }, // Initialize with any data structure you need
};

// Reducer to handle actions
function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, data: {...state.data, ...action.payload} };
    default:
      return state;
  }
}

// Create Context
const AppContext = createContext();

// Create Provider
export default function AppProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setData = (data) => {
    dispatch({ type: 'SET_DATA', payload: data });
  };

  return (
    <AppContext.Provider value={{ state, setData }}>
      {children}
    </AppContext.Provider>
  );
}

// Custom hook for consuming the context
export function useAppContext() {
  return useContext(AppContext);
}
