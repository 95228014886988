import { useState, CSSProperties } from "react";
import DotLoader from "react-spinners/DotLoader";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function Loading() {
  let [color, setColor] = useState("#6b21a8");

  return (
    <div className="py-12 grow flex items-center justify-center">

      <DotLoader
        color={color}
        loading={true}
        cssOverride={override}
        size={50}
        speedMultiplier={3}
      />
    </div>
  );
}

export default Loading;