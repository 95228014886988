import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

const Main = () => {
  const [meetingId, setMeetingId] = useState('');
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  useEffect(()=>{
   window.location.href = 'https://www.willo.video/';
  },[])


  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Main;
