import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import InformationCard from "./components/InformationCard";
import LanguageOptions from "./components/LanguageOptions";
import Footer from "./components/Footer";
import { useAppContext } from "./Provider/AppProvider";
import SignUp from "./components/SignUp";

const companyURL = "https://radleyjames.com/";
const company = "Radley James";

function App({ data, links }) {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);
  const { responsibilities, requirements, salary, title, location } = data;

  const content1 = {
    title: "Key Responsibilities:",
    content: responsibilities,
  };

  const content2 = {
    title: "Requirements:",
    content: requirements,
  };

  useEffect(() => {
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
  }, []);

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard
            location={location}
            salary={salary}
            company={company}
            companyURL={companyURL}
          />
          <LanguageOptions
            company={company}
            title={title}
            content1={content1}
            content2={content2}
            nextLink={links.step0}
          />
        </div>
      </main>
      <Footer />
      {showModal ? <SignUp callback={() => setShowModal(false)} /> : <></>}
    </div>
  );
}

export default App;
