export const DataOfQuantitativeTrader = {
  responsibilities: [
    "- Strategy Development: Design and implement systematic trading strategies for cryptocurrencies.",
    "- Research & Analysis: Identify profitable opportunities through in-depth quantitative research.",
    "- Market Monitoring: Adapt strategies based on market trends for optimal returns.",
    "- Collaborative Innovation: Work with research and tech teams to enhance algorithms and infrastructure.",
    "- Performance Tracking: Maintain thorough documentation and track performance for consistent improvement.",
  ],

  requirements: [
    "- Experience: Minimum of two years in live crypto trading with a proven success record.",
    "- Expertise: Strong knowledge of blockchain, digital assets, and major trading platforms (e.g., Binance, Coinbase Pro, TradingView).",
    "- Market Insight: Deep understanding of market dynamics, risk management, and trading psychology.",
    "- Education: Degree in finance, mathematics, or computer science is a plus but not required.",
  ],

  salary: "$180,000/y + bonus & PnL",

  title: "Quantitative Trader",

  location: "Remote / On-site",

  question1:
    "Describe your experience with cryptocurrency trading. What types of cryptocurrencies do you primarily trade, and which platforms do you prefer?",
  question2: [
    "How do you manage risk in a volatile market like cryptocurrency?",
    "Describe any specific techniques or tools you use for risk management.",
    "Provide an example of a particularly challenging trade that you successfully managed.",
    "What key metrics do you use to evaluate and adjust trading strategies?",
  ],
  question3:
    "Why are you interested in this role, and what unique skills would you bring to our team? Briefly introduce yourself and highlight any specialized skills or insights you have in the crypto space.",
};
